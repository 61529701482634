import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import logo from './img/yes.jpg';
import './HomePage.css'; // Import the new CSS file
import lett from './img/let.png';

const HomePage = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    asset_description: '',
    asset_make: '',
    vendor_name: '',
    invoice_number: '',
    invoice_date: null,
    installation_date: null,
    location: '',
    warrantee: '',
    asset_id_no: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    loadInvoices();
  }, []);

  useEffect(() => {
    const filtered = invoices.filter((invoice) =>
      invoice.invoice_number.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredInvoices(filtered);
  }, [searchQuery, invoices]);

  const loadInvoices = async () => {
    try {
      const response = await fetch('https://hello-hamaradhikar-com.preview-domain.com/php-api/constomer/getAllAssets.php');
      const data = await response.json();
      const sortedInvoices = data.sort((a, b) => Number(a.invoice_number) - Number(b.invoice_number));
      setInvoices(sortedInvoices);
    } catch (err) {
      console.error('Error fetching invoices:', err);
      setError('Failed to load invoices');
    }
  };

  const handleAddInvoice = async () => {
    try {
      const newInvoiceData = { 
        ...invoiceData,
        asset_id_no: Date.now().toString()
      };
      const response = await fetch('https://hello-hamaradhikar-com.preview-domain.com/php-api/constomer/AddAssets.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newInvoiceData),
      });
      if (response.ok) {
        loadInvoices();
        setIsModalOpen(false);
        resetInvoiceData();
      } else {
        throw new Error('Failed to add invoice');
      }
    } catch (err) {
      console.error('Error saving invoice:', err);
      setError('Failed to save invoice');
    }
  };

  const resetInvoiceData = () => {
    setInvoiceData({
      asset_description: '',
      asset_make: '',
      vendor_name: '',
      invoice_number: '',
      invoice_date: null,
      installation_date: null,
      location: '',
      warrantee: '',
      asset_id_no: ''
    });
    setError('');
  };

  const handleDeleteClick = async (id) => {
    try {
      if (id) {
        const response = await fetch(`https://hello-hamaradhikar-com.preview-domain.com/php-api/constomer/deleteAssets.php?asset_id=${id}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          loadInvoices();
        } else {
          throw new Error('Failed to delete invoice');
        }
      } else {
        alert('Delete Error', 'No ID provided for deletion.');
      }
    } catch (err) {
      console.error('Error deleting invoice:', err);
      setError('Failed to delete invoice');
    }
  };

  // Logout button logic
  const handleLogout = () => {
    localStorage.removeItem('user');
    sessionStorage.clear();
    navigate('/login'); // Redirect to login page
  };

  return (
    <div className="home-page-main">
      <header className="home-page-header">
        <div className="home-page-welcome">
          <img src={logo} alt="Logo" className="home-page-logo" />
          <h3>Welcome To Yes Seal Trust</h3>
        </div>
        <div className="home-page-total-assets">
          <h3>Total Assets</h3>
          <p>{invoices.length}</p>
        </div>
      </header>

      <div className="home-page-btn-container">
        <button onClick={() => setIsModalOpen(true)} className="home-page-btn-add">Add</button>
        <button onClick={handleLogout} className="home-page-btn-logout">Logout</button>
      </div>

      <div>
        <input
          className="home-page-input-search"
          type="text"
          placeholder="Search by Invoice Number"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="home-page-invoice-list">
        <h2>Assets List</h2>
        {filteredInvoices.length > 0 ? (
          <ul>
            {filteredInvoices.map((invoice) => (
              <li key={invoice._id} className="home-page-invoice-item">
                <span>{invoice.invoice_number}</span>
                <button
                  onClick={() => navigate(`/invoice/${invoice.asset_id_no}`)}
                  className="home-page-btn-get"
                >
                  Get
                </button>
                {/* <button
                  onClick={() => handleDeleteClick(invoice.asset_id_no)}
                  className="home-page-btn-delete"
                >
                  Delete
                </button> */}
              </li>
            ))}
          </ul>
        ) : (
          <div className="home-page-no-invoices">
            <img src={lett} alt="No invoices" />
            <p>No Assets available.</p>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="home-page-modal">
          <h2>Add New Asset</h2>
          {Object.keys(invoiceData).map((field) => (
            <div key={field} className="home-page-form-group">
              <label>{field.replace(/([A-Z])/g, ' $1').toUpperCase()}</label>
              {field === 'invoice_date' || field === 'installation_date' ? (
                <DatePicker
                  selected={invoiceData[field]}
                  onChange={(date) => setInvoiceData({ ...invoiceData, [field]: date })}
                  dateFormat="yyyy/MM/dd"
                  className="home-page-date-picker"
                  placeholderText={`Select ${field.replace(/([A-Z])/g, ' $1')}`}
                />
              ) : (
                <input
                  type="text"
                  value={invoiceData[field]}
                  onChange={(e) => setInvoiceData({ ...invoiceData, [field]: e.target.value })}
                  className="home-page-text-input"
                />
              )}
            </div>
          ))}
          <div className="home-page-modal-btn-group">
            <button onClick={handleAddInvoice} className="home-page-modal-btn-save">Save Details</button>
            <button onClick={() => setIsModalOpen(false)} className="home-page-modal-btn-cancel">Cancel</button>
          </div>

          {error && <p className="home-page-error-message">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default HomePage;
